import PropTypes from "prop-types"
import React from "react"
import { Collapse } from "@blueprintjs/core"
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus } from "@fortawesome/pro-solid-svg-icons"

var classNames = require("classnames")

class CollapseRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: this.props.isOpen ? true : false,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <div className="collapse-container">
        <div className="columns collapse-heading-icon">
          <div className="column is-6"></div>
          <div className="column is-12" onClick={this.handleClick}>
            {this.props.heading}
          </div>
          <div className="column is-1 collapse-icon">
            {!this.state.isOpen && (
              <>
                <FontAwesomeIcon icon={faPlus} onClick={this.handleClick} />
                {/* for SEO Purposes */}
                <div style={{ display: "none" }}>{this.props.paragraphs}</div> 
              </>
            )}
            {this.state.isOpen && (
              <FontAwesomeIcon icon={faMinus} onClick={this.handleClick} />
            )}
          </div>
          <div className="column is-5"></div>
        </div>
        <Collapse isOpen={this.state.isOpen}>
          <div className="columns collapse-row">
            <div className="column is-6"></div>
            <div className="column is-12" style={{ marginTop: "1rem" }}>
              {this.props.paragraphs}
            </div>
            <div className="column is-6"></div>
          </div>
        </Collapse>
      </div>
    )
  }
}

CollapseRow.propTypes = {
  isOpen: PropTypes.bool,
  heading: PropTypes.element,
  paragraphs: PropTypes.element,
}

export default CollapseRow
