import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import {
  faList,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Collapse } from "@blueprintjs/core"

const TableOfContents = ({ content }) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <div className="di-toc">
      <div className="columns ">
        <div className="column is-2" />
        <div className="column di-toc__header toc-header-title">
          <FontAwesomeIcon icon={faList} />
          Table of Contents
        </div>

        {isOpen ? (
          <div className="column is-4 di-toc__header toc-header-hide">
            <div onClick={() => setIsOpen(!isOpen)}>
              <span>Hide</span>
              <FontAwesomeIcon icon={faChevronUp} />
            </div>
          </div>
        ) : (
          <div className="column is-4 di-toc__header toc-header-show">
            <div onClick={() => setIsOpen(!isOpen)}>
              <span>Show</span>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        )}

        <div className="column is-2" />
      </div>
      <Collapse isOpen={isOpen}>{Subtitles(content)}</Collapse>
      {/* For SEO Purposes */}
      {!isOpen && <div style={{ display: "none" }} className="hidden-subtitles">{Subtitles(content)}</div>}
    </div>
  )
}

const Subtitles = (content) => {
  return (
    <div className="columns">
      <div className="column is-2" />

      <ol className="di-toc__list" role="list">
        <div className="columns">
          <div className="column is-4"></div>

          <div className="column">
            {content.map((section, i) => {
              return (
                <li key={i}>
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      document
                        .querySelector(
                          `#${section.title
                            .replace(/[\s+-]/g, "-")
                            .replace(/[^\w-]/g, "")
                            .toLowerCase()}`
                        )
                        .scrollIntoView({
                          behavior: "auto",
                          block: "start",
                        })
                    }}
                  >
                    <span className="title">{`${i + 1}. ${
                      section.title
                    }`}</span>
                  </a>

                  <ol role="list">
                    {section.subtitles.map((subtitle, i) => {
                      return (
                        <li key={i}>
                          <a
                            onClick={(e) => {
                              e.preventDefault()
                              document
                                .querySelector(
                                  `#${subtitle
                                    .replace(/[\s+-]/g, "-")
                                    .replace(/[^\w-]/g, "")
                                    .toLowerCase()}`
                                )
                                .scrollIntoView({
                                  behavior: "auto",
                                  block: "start",
                                })
                            }}
                          >
                            <span className="title">{subtitle}</span>
                          </a>
                        </li>
                      )
                    })}
                  </ol>
                </li>
              )
            })}
          </div>
        </div>
      </ol>

      <div className="column is-2" />
    </div>
  )
}

export default TableOfContents
