import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import TheaterVideo from "@components/Theater/TheaterVideo"
import CloseIcon from "../CloseIcon"
import NuvoImage from "../NuvoImage"
import classNames from "classnames"

import { Dialog, setHotkeysDialogProps } from "@blueprintjs/core"

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import "./DoctorModal.scss"

export default function Modal(props) {
  var modalClass = classNames({
    "c-menu": true,
    "dialog-one": true,
  })

  const data = useStaticQuery(graphql`
    query doctorQuery {
      allDoctorsJson(filter: { title: { eq: "nestor-karas-dds-md/" } }) {
        nodes {
          doctorModal {
            blurb
            heading
            buttonOne {
              buttonLabel
              modalBlurb
              modalHeading
              youtube
              imageId
            }
          }
        }
      }
    }
  `)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [activeText, setActiveText] = useState({})

  function toggleDialog(activeText) {
    setDialogOpen(!dialogOpen)
    setActiveText(activeText)
  }

  // if this is broken, update filter url in graphql query above
  let doctorModal = data.allDoctorsJson.nodes[0].doctorModal
  const staticModal = true

  if (!staticModal) {
    return (
      <React.Fragment>
        <Dialog
          canOutsideClickClose={true}
          enforceFocus={true}
          autoFocus={true}
          canEscapeKeyClose={true}
          isOpen={dialogOpen}
          usePortal={true}
          onClose={toggleDialog}
          className="anesthesia-dialog"
        >
          <div className="has-text-centered dialog-content">
            <CloseIcon onClick={toggleDialog} />
            <div className="columns">
              <div className="column is-8">
                <NuvoImage
                  cloudName="nuvolum"
                  publicId={activeText.imageId}
                  responsive
                  crop="scale"
                  width="auto"
                ></NuvoImage>
                {activeText.youtube && (
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${activeText.youtube}`}
                    controls={true}
                    playing={true}
                    onlyButton={true}
                    buttonClass="contained"
                  />
                )}
              </div>
              <div className="column is-2"></div>
              <div className="column">
                <h3 style={{ marginTop: 0, textAlign: "left" }}>
                  {activeText.modalHeading}
                </h3>
                <p style={{ textAlign: "left" }}>{activeText.modalBlurb}</p>
              </div>
            </div>
            {!activeText.youtube && <div style={{ height: "80px" }}></div>}
          </div>
        </Dialog>
        <div
          className="columns has-text-centered color-back anesthesia-modal"
          style={{ paddingTop: "40px", paddingBottom: "50px" }}
        >
          <div className="column is-5"></div>
          <div className="column">
            <h3>{doctorModal.heading}</h3>
            <MarkdownViewer markdown={doctorModal.blurb} />
            <div className="button-group-wrapper">
              <div className="button-group is-centered has-3-button">
                <div className="has-text-centered">
                  <a
                    className="standard-button contained"
                    onClick={() =>
                      toggleDialog({
                        modalHeading: doctorModal.buttonOne.modalHeading,
                        modalBlurb: doctorModal.buttonOne.modalBlurb,
                        youtube: doctorModal.buttonOne.youtube,
                        imageId: doctorModal.buttonOne.imageId,
                      })
                    }
                  >
                    {doctorModal.buttonOne.buttonLabel}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-5"></div>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <section className="body-sections section joshua-tree-content">
        <div className="columns" style={{ justifyContent: "center" }}>
          <div className="column is-5" />
          <div className="columns doctor-karas-box">
            <div className="column is-8 has-text-centered">
              <NuvoImage
                cloudName="nuvolum"
                publicId={doctorModal.buttonOne.imageId}
                responsive
                crop="scale"
                width="auto"
              ></NuvoImage>
              {doctorModal.buttonOne.youtube && (
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${doctorModal.buttonOne.youtube}`}
                  controls={true}
                  playing={true}
                  onlyButton={true}
                  buttonClass="contained"
                />
              )}
            </div>
            <div className="column is-2"></div>
            <div className="column doctor-karas-box-content">
              <h3 style={{ marginTop: 0, textAlign: "left" }}>
                {doctorModal.buttonOne.modalHeading}
              </h3>
              <p style={{ textAlign: "left" }}>
                {doctorModal.buttonOne.modalBlurb}
              </p>
            </div>
          </div>
          <div className="column is-5" />
        </div>
      </section>
    )
  }
}
